// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.home_page h2 {
  color: white;
margin-bottom: 0.5rem;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}.home_page h4 {
  color: white;
margin-bottom: 0.5rem;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/ui/Pages/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd,qBAAqB;AACrB,2CAA2C;AAC3C,CAAC;EACC,YAAY;AACd,qBAAqB;AACrB,2CAA2C;AAC3C","sourcesContent":[".home_page {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: white;\n}\n\n.home_page h2 {\n  color: white;\nmargin-bottom: 0.5rem;\ntext-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n}.home_page h4 {\n  color: white;\nmargin-bottom: 0.5rem;\ntext-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
