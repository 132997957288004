import React from "react";
import "./App.css"
import {BrowserRouter as Router, Routes, Route, BrowserRouter} from "react-router-dom"
import TimeTable from "./ui/TT/TimeTable";
import BackgroundImage from "./images/backgroupnd/BackgroundImg.svg";
import Lines from "./images/backgroupnd/Line.svg";
import NotFoundPage from "./ui/NotFoundPage/NotFoundPage";
import LoginPage from "./ui/login/LoginPage/LoginPage";
import {PrivateRoute} from "./ui/login/qr/components/PrivateRoute/PrivateRoute";
import Main from "./ui/Start/Main";
import HomePage from "./ui/Pages/HomePage/HomePage";
import Rooms from "./ui/Pages/room/Rooms";
import Attended from "./ui/Pages/Attended";
import Settings from "./ui/Pages/Settings";
import Noting from "./ui/Pages/Noting";
import Profile from "./ui/Pages/Profile";
import styled from "styled-components";
import Chat from "./ui/Pages/ChatPage/Chat";


function  App(){
    const Pages = styled.div`
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        //background: linear-gradient(to top, rgba(0, 0, 0, 0), #160825),
        url(${BackgroundImage}) no-repeat center center / cover,
        url(${Lines}) no-repeat center center / cover,
        #160825;

        h1 {
            font-size: calc(2rem + 2vw);
            background: linear-gradient(to right, #803bec 30%, #1b1b1b 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    `;

    return (
        <Pages>
            <div className="App">
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="timetable" element={<TimeTable />} />

                    <Route path="/" element={<PrivateRoute />}>
                        <Route path="home" element={<Main/>}>
                            <Route path="homep" element={<HomePage/>}/>
                            {/*<Route path="chat" element={<Chat/>}/>*/}
                            {/*<Route exact path="chat" render={(props) => <Chat {...props} />}/>*/}
                            <Route path="rooms" element={<Rooms/>}/>
                            <Route path="attended" element={<Attended/>}/>
                            <Route path="settings" element={<Settings/>}/>
                            <Route path="noting" element={<Noting/>}/>
                            <Route path="profile" element={<Profile/>}/>
                        </Route>
                        {/*<Route path="/addresses/details/:id" element={<DetailPage />} /> */}
                    </Route>
                </Routes>
            </div>
        </Pages>
    );
}

export default App;
