import React, {startTransition, useEffect, useMemo, useState} from "react";
import {
  getUsers,
  countNewMessages,
  findChatMessages,
  findChatMessage, getCurrentUser,
} from "./util/ApiUtil";
import "./ChatPage.css";
import {Button, message} from "antd";
import {chatActiveContact, chatMessages, loggedInUser} from "../../../atom/globalState";
import ScrollToBottom from "react-scroll-to-bottom";
import {useRecoilState, useRecoilValue} from "recoil";
import {useSelector} from "react-redux";

let stompClient = null;
const Chat = () => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);

  const loadCurrentUser = () => {
    getCurrentUser("@BaskakovAE")
        .then((response) => {
          setLoggedInUser(response);
        })
        .catch((error) => {
          console.log(error);
        });
  };
  const [text, setText] = useState("");
  const [contacts, setContacts] = useState([]);
  const [activeContact, setActiveContact] = useRecoilState(chatActiveContact);
  const [messages, setMessages] = useRecoilState(chatMessages);

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      // props.history.push("/login");
    }

    // Синхронная часть
    connect(); // Подключение к WebSocket может быть выполнено сразу

    startTransition(() => { // Асинхронные операции, влияющие на интерфейс
      loadContacts();
      loadCurrentUser();
    });
  }, []);

  useEffect(() => {
    if (activeContact === undefined) return;

    // Обертка синхронного вызова в startTransition
    startTransition(() => {
      findChatMessages(activeContact.id, currentUser?.id).then((msgs) =>
          setMessages(msgs)
      );
      loadContacts();
    });
  }, [activeContact]);

  const connect = () => {
    const Stomp = require("stompjs");
    let SockJS = require("sockjs-client");
    SockJS = new SockJS("http://ushell.ru:8082/ws");
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError); // Здесь происходит подключение к WebSocket, оно должно выполняться асинхронно.
  };

  const onConnected = () => {
    stompClient.subscribe(
        "/user/" + currentUser?.id + "/queue/messages",
        onMessageReceived
    );
  };

  const onError = (err) => {
    console.log(err);
  };

  const onMessageReceived = (msg) => {
    const notification = JSON.parse(msg.body);
    const active = JSON.parse(sessionStorage.getItem("recoil-persist")).chatActiveContact;

    if (active.id === notification.senderId) {
      findChatMessage(notification.id).then((message) => {
        const newMessages = JSON.parse(sessionStorage.getItem("recoil-persist")).chatMessages;
        newMessages.push(message);
        setMessages(newMessages);
      });
    } else {
      message.info("Received a new message from " + notification.senderName);
    }
    loadContacts();
  };

  const sendMessage = (msg) => {
    if (msg.trim() !== "") {
      const message = {
        senderId: currentUser?.id,
        recipientId: activeContact.id,
        message: msg,
      };
      stompClient.send("/app/chat", {}, JSON.stringify(message));

      startTransition(() => {
        const newMessages = [...messages];
        newMessages.push(message);
        setMessages(newMessages);
      });
    }
  };

  const loadContacts = () => {
    startTransition(() => {
      const promise = getUsers(currentUser?.name).then((users) =>
          users.listUsers.map((contact) =>
              countNewMessages(contact.id, currentUser?.id).then((count) => {
                contact.newMessages = count;
                return contact;
              }).catch((error) => console.error('Ошибка при подсчете новых сообщений', error))
          )
      );

      promise.then((promises) =>
          Promise.all(promises).then((users) => {
            setContacts(users);
            if (activeContact === undefined && users.length > 0) {
              setActiveContact(users[0]);
            }
          }).catch((error) => console.error('Ошибка при загрузке контактов', error)) // Обработайте ошибку
      );
    });
  };

  return (
      <div id="frame">
        <div id="sidepanel">
          <div id="profile">
            <div class="wrap">
              <img
                  id="profile-img"
                  src={currentUser?.profilePicture}
                  class="online"
                  alt=""
              />
              <p>{currentUser?.name}</p>
              <div id="status-options">
                <ul>
                  <li id="status-online" class="active">
                    <span class="status-circle"></span> <p>Online</p>
                  </li>
                  <li id="status-away">
                    <span class="status-circle"></span> <p>Away</p>
                  </li>
                  <li id="status-busy">
                    <span class="status-circle"></span> <p>Busy</p>
                  </li>
                  <li id="status-offline">
                    <span class="status-circle"></span> <p>Offline</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="search" />
          <div id="contacts">
            <ul>
              {contacts.map((contact) => (
                  <li
                      onClick={() => setActiveContact(contact)}
                      class={
                        activeContact && contact.id === activeContact.id
                            ? "contact active"
                            : "contact"
                      }
                  >
                    <div class="wrap">
                      <span class="contact-status online"></span>
                      <img id={contact.id} src={contact.profilePicture} alt="" />
                      <div class="meta">
                        <p class="name">{contact.name}</p>
                        {contact.newMessages !== undefined &&
                            contact.newMessages > 0 && (
                                <p class="preview">
                                  {contact.newMessages} new messages
                                </p>
                            )}
                      </div>
                    </div>
                  </li>
              ))}
            </ul>
          </div>
          <div id="bottom-bar">
            <button id="addcontact">
              <i class="fa fa-user fa-fw" aria-hidden="true"></i>{" "}
              <span>Profile</span>
            </button>
            <button id="settings">
              <i class="fa fa-cog fa-fw" aria-hidden="true"></i>{" "}
              <span>Settings</span>
            </button>
          </div>
        </div>
        <div class="content">
          <div class="contact-profile">
            <img src={activeContact && activeContact.profilePicture} alt="" />
            <p>{activeContact && activeContact.name}</p>
          </div>
          <ScrollToBottom class="messages">
            <ul>
              {messages.map((msg) => (
                  <li class={msg.senderId === currentUser?.id ? "sent" : "replies"}>
                    {msg.senderId !== currentUser?.id
                        // <img src={activeContact.profilePicture} alt=""/>
                    }
                    <p>{msg.message}</p>
                  </li>
              ))}
            </ul>
          </ScrollToBottom>
          <div class="message-input">
            <div class="wrap">
              <input
                  name="user_input"
                  size="large"
                  placeholder="Write your message..."
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      sendMessage(text);
                      setText("");
                    }
                  }}
              />
              <Button
                  icon={<i class="fa fa-paper-plane" aria-hidden="true"></i>}
                  onClick={() => {
                    sendMessage(text);
                    setText("");
                  }}
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Chat;