import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../utils/store/auth";
import "./HomePage.css";
import styled from "styled-components";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.value.myToken);

  const [myData, setMyData] = useState();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = () => {
    fetch("http://ushell.ru:9002"+"/info/me", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setMyData(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logoutFunction = () => {
    dispatch(logout());
    navigate("/login");
  };


  const Button = styled.button`
    background-color: #803bec;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem; /* Add some space above the button */
    font-weight: bold;
`;

  return (
        <div className="home_page">
          <h1>Welcome To App</h1>
          <h2>Username: {myData?.firstname} {myData?.middlename}</h2>
          {myData && (
              <h4>{JSON.stringify(myData)}</h4>
          )}
          <Button onClick={logoutFunction}>Logout</Button>
        </div>
  );
};

export default HomePage;
