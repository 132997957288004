import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Settings from "../Pages/Settings";
import Rooms from "../Pages/room/Rooms";
import Attended from "../Pages/Attended";
import Noting from "../Pages/Noting";
import styled from "styled-components";
import BackgroundImage from "../../images/backgroupnd/BackgroundImg.svg"
import Lines from "../../images/backgroupnd/Line.svg"

import { AnimatePresence } from "framer-motion";
import Profile from "../Pages/Profile";
import {Route, Routes} from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import Chat from "../Pages/ChatPage/Chat";


function Main() {

    // const location = useLocation();

    const Pages = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), #160825),
    url(${BackgroundImage}) no-repeat center center / cover,
    url(${Lines}) no-repeat center center / cover,
    #160825;


    h1 {
      font-size: calc(2rem + 2vw);
      background: linear-gradient(to right, #803bec 30%, #1b1b1b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  `;
    return (
        <>
            <Sidebar />
                <Pages>
                    <div className="App">
                        <AnimatePresence mode="wait">
                            <Routes>
                                <Route exact path="/homep" element={<HomePage/>}/>
                                <Route path="/chat" element={<Chat/>}/>
                                <Route path="/rooms" element={<Rooms/>}/>
                                <Route path="/attended" element={<Attended/>}/>
                                <Route path="/settings" element={<Settings/>}/>
                                <Route path="/noting" element={<Noting/>}/>
                                <Route path="/profile" element={<Profile/>}/>
                            </Routes>
                        </AnimatePresence>
                    </div>
                </Pages>
        </>
    );
}

export default Main;