import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./utils/store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import {recoilPersist} from "recoil-persist";
import {RecoilRoot} from "recoil";

const root = createRoot(
    document.getElementById("root")
);

const { RecoilPersist, updateState } = recoilPersist([], {
    key: "recoil-persist",
    storage: sessionStorage,
});


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RecoilRoot initializeState={updateState}>
                {/*<RecoilPersist />*/}
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </RecoilRoot>
        </Provider>
    </React.StrictMode>
);


serviceWorker.unregister();
reportWebVitals();

// root.render(
//     <React.StrictMode>
//        <Provider store={store}>
//             <BrowserRouter>
//                 <App />
//             </BrowserRouter>
//          </Provider>
//     </React.StrictMode>
// );
//

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")

// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
