import React from "react";
import "./NotFoundPage.css";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  let navigate = useNavigate();

  return (
    <div className="wrapper fadeIn">
      <div className="number">404</div>
      <div className="text">
        <span>Ooops...</span>
        <br />
        Address not found !
        <br />
        <h3 onClick={() => navigate("/")} className="redirect-home">
          home
        </h3>
      </div>
    </div>
  );
};

export default NotFoundPage;
