import React, { useState, useEffect } from 'react';
import { getWeekYear } from 'date-fns';

const TimeTable = () => {
    const [classroomData, setClassroomData] = useState({});
    const [selectedFloor, setSelectedFloor] = useState(4);
    const [weekType, setWeekType] = useState(-1); // 0 for even week, 1 for odd week

    // ... your useEffect hook ...

    // Calculate weekType based on the week number (you'll need to get this from somewhere)
    useEffect(() => {
        setWeekType(getWeekYear % 2 === 0 ? 1 : 0);
    }, []);

    useEffect(() => {
        const fetchClassroomData = async () => {
            try {
                const response = await fetch(
                    'http://ushell.ru:8082/timetable/classroom/floor?floor='+selectedFloor,
                    );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setClassroomData(data);
            } catch (error) {
                console.error('Error fetching classroom data:', error);
            }
        };
        fetchClassroomData();
    }, [selectedFloor]);

    const renderLesson = (pairNumber, slot) => {
        return (
            <div>

                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <div>
                    {pairNumber}
                    <span className="material-icons">schedule</span>
                    {slot.timeStart} - {slot.timeEnd}
                </div>
                <div>
                    <span className="material-icons">book</span>
                    {slot.subjectName}
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start'}}> {/* Use flexbox for alignment */}
                    <span className="material-icons" style={{marginRight: '10px'}}>person</span>
                    <ul style={{listStyle: 'none', padding: 0}}>
                        {slot.teacherList.split('\n').map((teacher, index) => {
                            const [lastName, firstName, middleName] = teacher.split(' ');
                            return (
                                <li key={teacher} style={{display: index === 0 ? 'inline-block' : 'block'}}>
                                    {`${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}. `}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div>
                    <span className="material-icons">group</span>
                    {slot.withWhom}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                    <h1>Timetable for</h1>
                </div>
                <select
                    style={{
                        fontSize: '20px', // Adjust font size as needed
                        padding: '10px 20px', // Add padding for visual size
                        border: '3px solid black',
                        borderRadius: '5px', // Add rounded corners if desired
                        appearance: 'none' // Hide default browser styling
                    }}
                    value={selectedFloor}
                    onChange={(e) => setSelectedFloor(parseInt(e.target.value))}>
                    {Array.from({length: 5}, (_, i) => i + 1).map(floor => (
                        <option key={floor} value={floor}>
                            Floor {floor}
                        </option>
                    ))}
                </select>
            </div>

            <table style={{borderCollapse: 'collapse', width: '100%'}}>

                <thead>
                <tr style={{borderBottom: '2px solid black'}}>
                    <th style={{textAlign: 'center', borderRight: '2px solid black'}}>
                        Дни недели
                    </th>
                    {Object.entries(classroomData).map(([classroomId]) => (
                        <th key={classroomId} style={{textAlign: 'center', borderRight: '2px solid black'}}>
                            Classroom {classroomId}
                        </th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {Array.from({length: 6}, (_, i) => i).map((dayIndex) => {
                    const dayNames = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница','Суббота'];

                    return (
                        <React.Fragment key={dayNames[dayIndex]}>
                            <tr key={dayNames[dayIndex]}>

                                <td style={{
                                    textAlign: 'center',
                                    borderRight: '2px solid black',
                                    width: '5%'
                                }}>
                                    {dayNames[dayIndex]}
                                </td>

                                {Object.entries(classroomData).map(([classroomId, schedule]) => (
                                    <td
                                        key={`${classroomId}-${dayNames[dayIndex]}`}
                                        style={{
                                            borderRight: '2px solid black',
                                            padding: '10px',
                                            textAlign: 'left',
                                            width: '14.28%', // Approximately 100%/7 for 7 columns
                                            verticalAlign: 'top' // Align vertically to the top
                                        }}
                                    >
                                        {Array.from({length: 8}, (_, i) => i + 1).map((pairNumber) => (
                                            <div key={pairNumber}>
                                                {schedule.main_schedule && schedule.main_schedule[weekType] ? (
                                                    schedule.main_schedule[weekType][dayNames[dayIndex]] &&
                                                    schedule.main_schedule[weekType][dayNames[dayIndex]][pairNumber] ?
                                                        (
                                                            <div>
                                                                <hr style={{border: '1px solid lightgray'}}/>
                                                                {renderLesson(pairNumber, schedule.main_schedule[weekType][dayNames[dayIndex]][pairNumber])}
                                                            </div>
                                                        ): <div></div>
                                                ) : <div>-</div>}
                                            </div>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                            {dayIndex < 6 && <tr style={{borderBottom: '2px solid black'}}></tr>}
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};


export default TimeTable;