import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QRCodeComponent from "../qr/components/QRCodeComponent/QRCodeComponent";
import { login } from "../../../utils/store/auth";
import { randomCodeGenerator } from "../qr/utils/randomCodeGenerator";
import "./LoginPage.css";
import {BASE_URL} from "../../../utils/Connect";
import {getCurrentUser} from "../../Pages/ChatPage/util/ApiUtil";
import {useRecoilState} from "recoil";
import {loggedInUser} from "../../../atom/globalState";

const LoginPage = () => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);

  const loadCurrentUser = () => {
    getCurrentUser("@BaskakovAE")
        .then((response) => {
          console.log(response);
          setLoggedInUser(response);
        })
        .catch((error) => {
          console.log(error);
        });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    room: randomCodeGenerator(20),
    code: randomCodeGenerator(25),
  });

  const [userCredientals, setUserCredientals] = useState({
    username: "",
    password: "",
  });

  const MINUTE_MS = 1200000;

  useEffect(() => {
    const interval = setInterval(() => {
      setValues({ ...values, code: randomCodeGenerator(25) });
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const loginHandler = (e) => {
    e.preventDefault();
    loginRequest();
  };

  const loginRequest = async () => {
    console.log(JSON.stringify({
      email: userCredientals.username,
      password: userCredientals.password,
    }))
    await fetch("http://ushell.ru:9002"+"/auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userCredientals.username,
        password: userCredientals.password,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        localStorage.setItem("accessToken", responseData.accessToken);
        loadCurrentUser(responseData);

        if (responseData.accessToken !== undefined) {
          dispatch(
            login({
              myToken: responseData.accessToken,
            })
          );
          navigate("/home/homep");

        } else {
          alert("Wrong credientals!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container className="loginPage">
      <Form className="formLogin" onSubmit={loginHandler}>
        <QRCodeComponent codes={values} />

        <Form.Group className="mb-3 text-center">
          <span className="text"> or</span>
          <br />
        </Form.Group>
        <Form.Group className="mb-3 text-start" controlId="formBasicUsername">

          <Form.Label style={{color: "wheat"}}>Username</Form.Label>
          <Form.Control
            type="text"
            required
            value={userCredientals.username}
            onChange={(e) =>
              setUserCredientals({
                ...userCredientals,
                username: e.target.value,
              })
            }
            placeholder="Enter username"
          />
        </Form.Group>

        <Form.Group className="mb-3 text-start" controlId="formBasicPassword">
          <Form.Label style={{color: "wheat"}} >Password</Form.Label>
          <Form.Control
            type="password"
            required
            value={userCredientals.password}
            onChange={(e) =>
              setUserCredientals({
                ...userCredientals,
                password: e.target.value,
              })
            }
            placeholder="Password"
          />
        </Form.Group>

        <div className="d-grid gap-2">
          <Button variant="primary" type="submit">
            Login
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default LoginPage;
