import React, { useState } from "react";
import "./Sidebar.css";
//All the svg files
import logo from "../../images/sidebar/Logo.svg";
import Home from "../../images/sidebar/Home.svg";
import Messenger from "../../images/sidebar/Messenger.svg";
import Rooms from "../../images/sidebar/Rooms.svg";
import Attended from "../../images/sidebar/Attendens.svg";
import Exit from "../../images/sidebar/Exit.svg"
import Profile from "../../images/sidebar/Profile.svg";
import Noting from "../../images/sidebar/Noting.svg";
import Settings from "../../images/sidebar/Settings.svg";

import styled from "styled-components";
import { NavLink } from "react-router-dom";

// хуйня для красоты когда ты выбрвл балу
const Container = styled.div`
  position: fixed;
  left: 10px;

  .active {
    border-right: 4px solid #C183FF;
    background-image: linear-gradient(to right, rgba(42, 42, 42, 0), #C183FF);

    img {
      fill: #C183FF;
    }
  }
`;

// Кнопка разворацивания меню
const Button = styled.button`
  background-color: #382E43;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 25px;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  background-color: #382E43;
  width: 110px;
  height: 90vh;
  margin-top: 1rem;
  border-radius: 20px 20px 20px 20px;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

const Logo = styled.div`
  width: 60px;
  padding: 1rem 0;
  text-align: center;
  color: var(--white);
  img {
    width: 100%;
    
  }
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #382E43;
  padding: 2rem 0;
  position: absolute;
  top: 8rem;
  left: 25px;
  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  // действия при наведении 

  // при наведении 
  &:hover {
    img {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
  }

  img {
    width: 1.5rem;
    height: auto;
    filter: invert(15%) sepia(4%) saturate(1033%) hue-rotate(169deg) brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
`;

const ExitBut = styled.div`

  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
 &:hover {
    img {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
  }
  img {
    width: 2rem;
    height: auto;
    cursor: pointer;
    filter: invert(0%) sepia(4%) saturate(1033%) hue-rotate(169deg) brightness(78%) contrast(85%);
  }
`;


const Sidebar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <Container>
      <Button clicked={click} onClick={() => handleClick()}>
      </Button>

      <SidebarContainer>

        <Logo>
          <img src={logo} alt="logo"/>
          <text>Ushell</text>
        </Logo>


        <SlickBar clicked={click}>
          <li>Основные</li>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/homep">
            <img src={Home} alt="Home"/>
            <Text clicked={click}>Home</Text>
          </Item>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/chat">
            <img src={Messenger} alt="Messenger"/>
            <Text clicked={click}>Messenger</Text>
          </Item>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/rooms">
            <img src={Rooms} alt="Rooms"/>
            <Text clicked={click}>Rooms</Text>
          </Item>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/attended">
            <img src={Attended} alt="Attendens"/>
            <Text clicked={click}>Attended</Text>
          </Item>
          <li>Инструменты</li>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/settings">
            <img src={Settings} alt="Settings"/>
            <Text clicked={click}>Settings</Text>
          </Item>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/noting">
            <img src={Noting} alt="Noting"/>
            <Text clicked={click}>Noting</Text>
          </Item>
          <Item onClick={() => setClick(false)} activeClassName="active" to="/home/profile">
            <img src={Profile} alt="Profile"/>
            <Text clicked={click}>Profile</Text>
          </Item>

        </SlickBar>

        <ExitBut onClick={() => console.log("Exit clicked")}>
          <img src={Exit} alt="Exit Icon"/>
        </ExitBut>

      </SidebarContainer>
    </Container>
  );
};

export default Sidebar;