// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginPage {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  background: #e1e0e0;
}



.text {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.692);
  margin: 8px 0px;
  font-size: 20px;
}

.text::before,
.text::after {
  content: "";
  flex-grow: 1;
  background: rgb(255, 255, 255);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/login/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB;;;;AAIA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".loginPage {\n  display: flex !important;\n  flex-direction: row !important;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 50px;\n  background: #e1e0e0;\n}\n\n\n\n.text {\n  display: flex;\n  flex-basis: 100%;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.692);\n  margin: 8px 0px;\n  font-size: 20px;\n}\n\n.text::before,\n.text::after {\n  content: \"\";\n  flex-grow: 1;\n  background: rgb(255, 255, 255);\n  height: 1px;\n  font-size: 0px;\n  line-height: 0px;\n  margin: 0px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
