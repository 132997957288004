const BASE_URL = "http://ushell.ru:9072"

const request = (options) => {
  const headers = new Headers();

  if (options.setContentType !== false) {
    headers.append("Content-Type", "application/json");
  }

  if (localStorage.getItem("accessToken")) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("accessToken")
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {

      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function login(loginRequest) {
  return request({
    url: "http://ushell.ru:9074/auth/login",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}


export function getCurrentUser(username) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url:"http://ushell.ru:9072/chat/users/me",
    method: "POST",
    body: JSON.stringify({ username:username}),
  });
}

export function getUsers(username) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BASE_URL + "/chat/users/summaries",
    method: "POST",
    body: JSON.stringify({ username: username }),
  });
}

export function countNewMessages(senderId, recipientId) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BASE_URL + "/chat/message/count",
    method: "POST",
    body: JSON.stringify({
      senderId: senderId,
      recipientId: recipientId
    }),
  });
}

export function findChatMessages(senderId, recipientId) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BASE_URL + "/chat/message/messages",
    method: "POST",
    body: JSON.stringify({
      senderId: senderId,
      recipientId: recipientId
    }),
  });
}

export function findChatMessage(id) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BASE_URL + "/chat/message/messages/" + id,
    method: "GET",
  });
}
